type RedirectOptions = {
	replace?: boolean
}
export const redirect = (url: string, options: RedirectOptions = {}) => {
	const config = {
		replace: false,
		...options,
	};

	// console.info(`Redirecting to ${url}`);
	if (config.replace && typeof url) {
		window.location.replace(url);
	} else {
		window.location.href = url;
	}
};

export const redirectToLogin = () => {
	redirect('/account/sign-in/');
};
